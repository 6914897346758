import { useMediaQuery, useTheme } from '@mui/material'
import { GlobalContext } from '@providers/globalStore'
import { FormStatusEnum } from '@services/model/form/form.model'
import { useContext } from 'react'
import NavGroup from './nav/navGroup'
import useLocalStorage from '@hooks/useLocalStorage'
import { RolesEnum } from '@services/model/user.model'
import { parseLocalStorageValue } from '@utils/localStorage'
import cleanString from '@utils/cleanString'
import { OrganizationNameEnum } from '@services/model/admin.model'
export interface SideMenuChildren {
  id: string
  title: string
  caption?: string
  category?: string
  type: 'item' | 'sub-item' | 'collapse'
  url?: string
  urlPattern?: string
  rights?: string[]
  exactMatch?: boolean
  rightsCallback?: (hasRightByCatAndCode: any) => boolean
  // Here we use mui icons
  acronym?: string
  colorCode?: string
  children?: SideMenuChildren[]

  // hidden field will not be used for type collapse
  hidden?: boolean
  disabled?: boolean
}

export interface SideMenu {
  id: string
  title: string
  hideMenuTitle?: boolean
  caption: string
  hidden?: boolean
  type: 'group'
  children: SideMenuChildren[]
}
const userInfo = window.localStorage.getItem('usr')
let user: any
if (userInfo) {
  user = JSON.parse(userInfo)
}
let isRole = (roleEnum) => {
  return user?.roles?.includes(roleEnum)
}

const moduleDailyRecordSystemItem: SideMenu = {
  id: 'module-daily-record-summary-item',
  title: '', //Smart Waste Management Tool
  caption: '', //Daily Record Summary
  type: 'group',
  children: [
    {
      id: 'daily-record-summary-item',
      title: 'Chit Record',
      type: 'collapse',
      acronym: 'CHIT',
      urlPattern: '/daily-record-summary-item',
      url: '/daily-record-summary-item/all-records',
      colorCode: '#619B51',
      children: [
        {
          id: 'daily-record-summary-item-batch',
          title: 'Create',
          rightsCallback: (hasRightByCatAndCode) => {
            //TODO change back to api
            return hasRightByCatAndCode(FormStatusEnum.DailyRecordSummary, ['C'])
          },
          type: 'sub-item',
          url: '/daily-record-summary-item/batch',
          colorCode: '#619B51',
        },
        {
          id: 'daily-record-summary-item-c-easy',
          title: 'C·Easy',
          rightsCallback: (hasRightByCatAndCode) => {
            //TODO change back to api
            return hasRightByCatAndCode(FormStatusEnum.DailyRecordSummary, ['C'])
          },
          type: 'sub-item',
          url: '/daily-record-summary-item/c-easy',
          colorCode: '#619B51',
        },
        {
          id: 'daily-record-summary-item-all-records',
          title: 'All Records',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.DailyRecordSummaryDraft, ['R']) ||
              isRole(RolesEnum.GUEST) ||
              false
            )
          },
          type: 'sub-item',
          url: '/daily-record-summary-item/all-records',
          colorCode: '#619B51',
        },
      ],
    },
  ],
}

const moduleDailyRecordSummary: SideMenu = {
  id: 'module-daily-record-summary',
  title: '', //Smart Waste Management Tool
  caption: '', //Daily Record Summary
  type: 'group',
  children: [
    {
      id: 'daily-record-summary',
      title: 'Daily Record Summary',
      type: 'collapse',
      acronym: 'DRS',
      urlPattern: '/daily-record-summary',
      url: '/daily-record-summary/all-records',
      colorCode: '#619B51',
      children: [
        {
          id: 'daily-record-summary-create',
          title: 'Create',
          rightsCallback: (hasRightByCatAndCode) => {
            return hasRightByCatAndCode(FormStatusEnum.DailyRecordSummary, ['C']) || false
          },
          type: 'sub-item',
          url: '/daily-record-summary',
          colorCode: '#619B51',
        },
        {
          id: 'daily-record-summary-my-records',
          title: 'My Actions',
          rightsCallback: (hasRightByCatAndCode) => {
            return hasRightByCatAndCode(FormStatusEnum.DailyRecordSummaryDraft, ['R']) || false
          },
          type: 'sub-item',
          url: '/daily-record-summary/my-records',
          colorCode: '#619B51',
        },
        {
          id: 'daily-record-summary-all-records',
          title: 'All Records',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.DailyRecordSummaryDraft, ['R']) ||
              isRole(RolesEnum.GUEST) ||
              false
            )
          },
          type: 'sub-item',
          url: '/daily-record-summary/all-records',
          colorCode: '#619B51',
        },
      ],
    },
  ],
}

const moduleMonthlyWasteFlowTable: SideMenu = {
  id: 'module-monthly-waste-flow-table',
  title: '',
  caption: '', //Monthly Waste Flow Table
  type: 'group',
  children: [
    {
      id: 'monthly-waste-flow-table',
      title: 'Monthly Waste Flow Table',
      type: 'collapse',
      acronym: 'MWFT',
      urlPattern: '/monthly-waste-flow-table',
      url: '/monthly-waste-flow-table/all-records',
      colorCode: '#B2B552',
      children: [
        {
          id: 'mwf-create',
          title: 'Create',
          type: 'sub-item',
          url: '/monthly-waste-flow-table/',
          rightsCallback: (hasRightByCatAndCode) => {
            return hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTable, ['C']) || false
          },
          colorCode: '#B2B552',
        },
        {
          id: 'mwf-my-records',
          title: 'My Actions',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableDraft, ['R', 'U', 'D']) ||
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableReview, ['R', 'U', 'D']) ||
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableRejected, ['R', 'U', 'D']) ||
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableApproved, ['R', 'U', 'D']) ||
              false
            )
          },
          url: '/monthly-waste-flow-table/my-records',
          colorCode: '#B2B552',
        },
        {
          id: 'mwf-all-records',
          title: 'All Records',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableDraft, ['R', 'U', 'D']) ||
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableReview, ['R', 'U', 'D']) ||
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableRejected, ['R', 'U', 'D']) ||
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableApproved, ['R', 'U', 'D']) ||
              isRole(RolesEnum.GUEST) ||
              false
            )
          },
          url: '/monthly-waste-flow-table/all-records',
          colorCode: '#B2B552',
        },
        {
          id: 'mwf-upload',
          title: 'Upload',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableUploadPlatform, ['C']) ||
              hasRightByCatAndCode(FormStatusEnum.MonthlyWasteFlowTableUploadPlatformDraft, [
                'R',
                'U',
                'D',
              ]) ||
              false
            )
          },
          url: '/monthly-waste-flow-table-upload/',
          colorCode: '#B2B552',
        },
      ],
    },
  ],
}

const moduleWasteForecastTable: SideMenu = {
  id: 'module_waste_forecast_table',
  title: '',
  caption: '',
  type: 'group',
  children: [
    {
      id: 'wft-create',
      title: 'Waste Forecast Table',
      type: 'collapse',
      acronym: 'WFT',
      urlPattern: '/waste-forecast-table',
      url: '/waste-forecast-table/all-records',
      colorCode: '#C18B30',
      children: [
        {
          id: 'waste-forecast-table',
          title: 'Create',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return hasRightByCatAndCode(FormStatusEnum.WasteForecastTable, ['C']) || false
          },
          url: '/waste-forecast-table/',
          colorCode: '#C18B30',
        },
        {
          id: 'wft-all-records',
          title: 'All Records',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.WasteForecastTable, ['C']) ||
              hasRightByCatAndCode(FormStatusEnum.WasteForecastTableDraft, ['R', 'U', 'D']) ||
              isRole(RolesEnum.GUEST) ||
              false
            )
          },
          url: '/waste-forecast-table/all-records',
          colorCode: '#C18B30',
        },
      ],
    },
  ],
}

const moduleWasteManagementPlan: SideMenu = {
  id: 'module_4_waste_management_plan',
  title: '',
  caption: '',
  type: 'group',
  children: [
    {
      id: 'waste-management-plan',
      title: 'Waste Management Plan',
      type: 'collapse',
      acronym: 'WMP',
      urlPattern: '/waste-management-plan',
      url: '/waste-management-plan/all-records',
      colorCode: '#F6871F',
      children: [
        {
          id: 'wmp-template',
          title: 'Template',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.WasteManagementPlanTemplate, ['C']) ||
              hasRightByCatAndCode(FormStatusEnum.WasteManagementPlanTemplateDraft, [
                'R',
                'U',
                'D',
              ]) ||
              isRole(RolesEnum.GUEST) ||
              false
            )
          },
          url: '/waste-management-plan-template/',
          colorCode: '#F6871F',
        },
        {
          id: 'wmp-upload',
          title: 'Upload',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.WasteManagementPlanUploadPlatform, ['C']) || false
            )
          },
          url: '/waste-management-plan/',
          colorCode: '#F6871F',
        },
        {
          id: 'wmp-all-records',
          title: 'All Records',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.WasteManagementPlanUploadPlatform, ['C']) ||
              hasRightByCatAndCode(FormStatusEnum.WasteManagementPlanUploadPlatformDraft, [
                'R',
                'U',
                'D',
              ]) ||
              isRole(RolesEnum.GUEST) ||
              false
            )
          },
          url: '/waste-management-plan/all-records',
          colorCode: '#F6871F',
        },
      ],
    },
  ],
}
export const getOrganizationIdByName = (organizationName: string) => {
  const { state: globalState } = useContext(GlobalContext)
  const organizationId = globalState?.organizationMetaList?.find(
    (organization) => cleanString(organization.name) === cleanString(organizationName),
  )?.id
  return organizationId
}

const dashboard: SideMenu = {
  id: 'dashboard',
  title: 'Smart Waste Management Tool',
  caption: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'collapse',
      acronym: 'DASH',
      urlPattern: '/dashboard',
      url: '/dashboard/project',
      colorCode: '#F16959',
      children: [
        {
          id: 'project',
          title: 'Project Dashboard',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              true || // TODO: remove this line once confirm dashboard right
              isRole(RolesEnum.GUEST) ||
              false
            )
          },
          url: '/dashboard/project',
          colorCode: '#F16959',
        },
        {
          id: 'cic',
          title: 'CIC Dashboard',
          type: 'sub-item',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              parseLocalStorageValue('usr')?.organization?.id ===
                getOrganizationIdByName(OrganizationNameEnum.cic) ||
              getOrganizationIdByName(OrganizationNameEnum.system) ||
              isRole(RolesEnum.GUEST) ||
              false
            )
          },
          url: '/dashboard/cic',
          colorCode: '#F16959',
        },
      ],
    },
  ],
}

const monthlyReport: SideMenu = {
  id: 'monthly-report',
  title: '',
  caption: '', //Monthly Waste Flow Table
  type: 'group',
  children: [
    {
      id: 'monthly-report',
      title: 'Monthly Report',
      type: 'collapse',
      acronym: 'MR',
      urlPattern: '/monthly-report',
      url: '/monthly-report',
      colorCode: '#FFCC4B',
      children: [
        {
          id: 'mr-upload',
          title: 'Upload',
          type: 'sub-item',
          url: '/monthly-report/',
          rightsCallback: (hasRightByCatAndCode) => {
            return hasRightByCatAndCode(FormStatusEnum.MonthlyReport, ['C']) || false
          },
          colorCode: '#FFCC4B',
        },
        {
          id: 'mr-upload-all-records',
          title: 'All Records',
          type: 'sub-item',
          url: '/monthly-report/all-records',
          rightsCallback: (hasRightByCatAndCode) => {
            return (
              hasRightByCatAndCode(FormStatusEnum.MonthlyReport, ['C']) ||
              hasRightByCatAndCode(FormStatusEnum.MonthlyReportDraft, ['R', 'U', 'D']) ||
              false
            )
          },
          colorCode: '#FFCC4B',
        },
      ],
    },
  ],
}

export const menuItems: SideMenu[] = [
  dashboard,
  moduleDailyRecordSystemItem,
  moduleDailyRecordSummary,
  moduleMonthlyWasteFlowTable,
  moduleWasteForecastTable,
  moduleWasteManagementPlan,
  monthlyReport,
]

const MenuList = () => {
  const { state: globalState } = useContext(GlobalContext)
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const { hasRightByCatAndCode } = useContext(GlobalContext)
  const [currentContract, setCurrentContract] = useLocalStorage<any | undefined>(
    'currentContract',
    undefined,
  )
  const [currentProject, setCurrentProject] = useLocalStorage<any | undefined>(
    'currentProject',
    undefined,
  )
  const disableNav = !currentContract?.contractId || !currentProject?.id

  const navItems = menuItems.map((group) => {
    group?.children.map((collapse) => {
      collapse?.children?.map((subItem) => {
        subItem.hidden = !subItem?.rightsCallback!(hasRightByCatAndCode)
      })
      collapse.hidden =
        collapse?.children?.filter((x) => x.hidden === true)?.length === collapse?.children?.length
    })

    return (
      <>
        <NavGroup
          item={group}
          disabled={disableNav} // disable menu if no contract is selected
          leftDrawerOpened={globalState.leftDrawerOpened}
          key={group.id}
        />
      </>
    )
  })

  return <>{disableNav ? 'Please select a Project First' : navItems}</>
}

export default MenuList
